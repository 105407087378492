import React from "react";

export default function OtherInfluencers() {
  return (
    <div className="container otherinfluencers">
      <h2 className="text-center title-heading">
        Other Influencers and Engagement
      </h2>
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-6 mb-4">
              <div className="card h-100  d-flex flex-column">
                <div className="card-body">
                  <h5 className="card-title">
                    Abhishek Mishra - Ironman and a Ultra Runner
                  </h5>
                  <p className="card-text">Followers - 11,100</p>
                  <p className="card-text">Video Plays - 1851</p>
                  <p className="card-text">Likes - 72</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="card h-100 d-flex flex-column">
                <div className="card-body">
                  <h5 className="card-title">Captain Avinash Chimanka</h5>
                  <p className="card-text">
                    Ultra Runner - Finishing 100 miler in Hell Race
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="card h-100 d-flex flex-column">
                <div className="card-body">
                  <h5 className="card-title">Last Date To Register Post</h5>
                  <p className="card-text">Video Plays - 43852</p>
                  <p className="card-text">Likes - 1311</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="card h-100 d-flex flex-column">
                <div className="card-body">
                  <h5 className="card-title">Medal Unveil Reel</h5>
                  <p className="card-text">Reach - 19,644</p>
                  <p className="card-text">Video Plays - 38852</p>
                  <p className="card-text">Likes - 778</p>
                  <p className="card-text">Shares - 66</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
              <div class="card p-5">
              <h2 className="text-center mb-4">Instagram Record Engagement</h2>
          <h6 className="text-center mb-4">20th Sept. to 04th December</h6>
                <div class="card-body">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                    Watch Time of 80 HRS 7 Mins 14 Seconds
                    </li>
                    <li class="list-group-item">
                    181,894 Impressions
                    </li>
                    <li class="list-group-item">Sports Celebrity Engagement</li>
                    <li class="list-group-item">
                    34,905 Accounts Reached
                    </li>
                    <li class="list-group-item">
                    89,200 Heartfulness Followers and 39,200 KSV followers engage
                  with GGKR Page
                    </li>
                  </ul>
                </div>
              </div>
        </div>
      </div>
    </div>
  );
}
