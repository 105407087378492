import React from "react";

import event1 from "../../assets/images/feb2023/event-stories/eventpic1.png";

import event2 from "../../assets/images/feb2023/event-stories/eventpic2.png";

import event3 from "../../assets/images/feb2023/event-stories/eventpic3.png";

import event4 from "../../assets/images/feb2023/event-stories/eventpic4.png";

export default function Eventstorypages() {
  return (
    <div className="event-story-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="title-heading"> EVENT STORY </h3>
           <p className="pt-1">
              It was ideated that people come and run in the beautiful Kanha
              environment, and feel the place with their hearts. Simultaneously,
              there was a purpose in mind- to help Forests by Heartfulness
              initiative through this event.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <img className="img-fluid" src={event1} width="100%" alt="event1" />
          </div>
          <div className="col-md-4">
            <img className="img-fluid" src={event2} width="100%" alt="event1" />
          </div>
          <div className="col-md-4">
            <img className="img-fluid" src={event3} width="100%" alt="event1" />
          </div>

          <div className="col-md-4">
            <img className="img-fluid" src={event4} width="100%" alt="event1" />
          </div>
        </div>
      </div>
    </div>
  );
}
