import React from "react";

import greenbg from "../../assets/images/feb2023/number-of-winner/green-bg.png";

import skybg from "../../assets/images/feb2023/number-of-winner/sky-bg.png";

import darkbg from "../../assets/images/feb2023/number-of-winner/dark-bg.png";

import subbg from "../../assets/images/feb2023/number-of-winner/sub-bg.png";

import usericon from "../../assets/images/feb2023/number-of-winner/usericon.png";

import gkrpic from "../../assets/images/nov2023/number_of_runners.png";


export default function Numberofrunner() {
  return (
    <div className="number-of-runner-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="title-heading"> NUMBER OF RUNNERS </h3>
          </div>
        </div>
        <div className="row px-3">
          <div className="col-md-6">
          <img src={gkrpic} className="img-fluid" alt="GKR" />
          </div>
          <div className="col-md-6">
          <div className="row">
          <div className="col-md-6 my-2">
            <div className="item-wrapper">
              <img
                className="img-fluid"
                src={greenbg}
                width="100%"
                alt="event1"
              />
              <div className="count-order">
                <h3> 350 </h3>

                <p>
                  {" "}
                  <span className="usericonpic">
                    {" "}
                    <img
                      className="img-fluid"
                      src={usericon}
                      width="100%"
                      alt="usericon"
                    />{" "}
                  </span>{" "}
                  21 Km
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 my-2">
            <div className="item-wrapper">
              <img
                className="img-fluid"
                src={skybg}
                width="100%"
                alt="event1"
              />
              <div className="count-order">
                <h3> 747 </h3>

                <p>
                  {" "}
                  <span className="usericonpic">
                    {" "}
                    <img
                      className="img-fluid"
                      src={usericon}
                      width="100%"
                      alt="usericon"
                    />{" "}
                  </span>{" "}
                  10 Km
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 my-2">
            <div className="item-wrapper">
              <img
                className="img-fluid"
                src={darkbg}
                width="100%"
                alt="event1"
              />
              <div className="count-order">
                <h3> 1425 </h3>

                <p>
                  {" "}
                  <span className="usericonpic">
                    {" "}
                    <img
                      className="img-fluid"
                      src={usericon}
                      width="100%"
                      alt="usericon"
                    />{" "}
                  </span>{" "}
                  5 Km
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 my-2">
            <div className="item-wrapper">
              <img
                className="img-fluid"
                src={subbg}
                width="100%"
                alt="event1"
              />
              <div className="count-order">
                <h3> 678 </h3>

                <p>
                  {" "}
                  <span className="usericonpic">
                    {" "}
                    <img
                      className="img-fluid"
                      src={usericon}
                      width="100%"
                      alt="usericon"
                    />{" "}
                  </span>{" "}
                  2 Km
                </p>
              </div>
            </div>
          </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}
