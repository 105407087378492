import React, { useState, useEffect } from "react";
import { SRLWrapper } from "simple-react-lightbox";
import Carousel from "react-multi-carousel";
import { galleryNov2023 } from "../../assets/data/gallerynov2023";
import { slice } from "lodash";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 3,
      partialVisibilityGutter: 0,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
      partialVisibilityGutter: 30,
    },
  };


export default function PrintMedia() {
    const [galleryImages2, setGalleryImages] = useState([]);

    // const [key, setKey] = useState("kanha");
  
    useEffect(() => {
      setGalleryImages(slice(galleryNov2023, 0, 8));
    }, []);
  
    const ButtonGroup = ({ next, previous }) => {
      return (
        <div className="carousel-button-group">
          <div className="navigations">
            <button onClick={() => previous()}>
              {/* <img src={treeLeftIconGreen} alt='treeLeftIconGreen' /> */}
            </button>
            <button onClick={() => next()}>
              {/* <img src={treeRightIconGreen} alt='treeRightIconGreen' /> */}
            </button>
          </div>
        </div>
      );
    };
  
    return (
      <div className="gallery-lightbox-wrapper" style={{ padding: "10px 0px" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section pad-photography">
                <h3 className="title-heading"> Print Media Coverage </h3>
  
                <SRLWrapper>
                  <Carousel
                    arrows={true}
                    autoPlaySpeed={3000}
                    draggable
                    infinite
                    keyBoardControl
                    minimumTouchDrag={80}
                    partialVisible
                    renderButtonGroupOutside={true}
                    swipeable
                    responsive={responsive}
                    customButtonGroup={<ButtonGroup />}
                  >
                    {galleryImages2.map((item) => {
                      return (
                        <div className="img-wrapper" key={item.id}>
                          <img
                            src={item.img}
                            className="img-fluid"
                            alt={item.alt}
                          />
                          <div className="overlay">
                            <i className="icon icon-arrows-fullscreen"></i>
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                </SRLWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
