import React from "react";
import Layout from "../layout/index";
import Seo from "../components/seo/index";
import Buttonevents from "../components/nov2023/buttoneventsfeb";
import Eventstorypages from "../components/nov2023/eventstory";
import Numberofrunner from "../components/nov2023/numberofrunner";
import RecentPicture from "../components/nov2023/recentpicture";
import SimpleReactLightbox from "simple-react-lightbox";
import Socialiconright from "../components/home/social-icon-right";
import DigitalDomination from "../components/nov2023/digitaldomination";
import CelebritiesTalking from "../components/nov2023/celebritiestalking";
import OtherInfluencers from "../components/nov2023/otherinfluencers";
import PrintMedia from "../components/nov2023/printmedia";

const Nov2023Page = () => {
  const navigatetopage = (data) => {
    console.log(data);
  };
  return (
    <div>
      <Seo title="Green Heartfulness Run - Feb 2023" />
      <Layout isSticky>
        <Buttonevents navigatetopage={navigatetopage} />
        <section>
        <Socialiconright />
        </section>
        <section id="eventstory">
          <Eventstorypages />
        </section>
        <section id="celebritiestalking">
          <CelebritiesTalking />
        </section>
        <section id="otherinfluencers">
          <OtherInfluencers />
        </section>
        <section id="digitaldomination">
          <DigitalDomination />
        </section>
        <section id="picture">
          {
          <SimpleReactLightbox>
            <section id="recentplantation">
              <RecentPicture />
            </section>
          </SimpleReactLightbox>
          }
        </section>
        <section id="numberofrunner">
          <Numberofrunner />
        </section>
        <section id="printmedia">
          {
          <SimpleReactLightbox>
            <section id="recentplantation">
            <PrintMedia />
            </section>
          </SimpleReactLightbox>
          }
        </section>
      </Layout>
    </div>
  );
};

export default Nov2023Page;
