import React from "react";

import event1 from "../../assets/images/feb2023/event-stories/eventpic1.png";

import event2 from "../../assets/images/feb2023/event-stories/eventpic2.png";

import event3 from "../../assets/images/feb2023/event-stories/eventpic3.png";

import event4 from "../../assets/images/feb2023/event-stories/eventpic4.png";

export default function DigitalDomination() {
  return (
    <div className="event-story-wrapper">
      <div class="container">
        <div class="row">
          <div class="text-center my-5">
            <h2 className="title-heading">Digital Domination</h2>
            <h5>GRANULES Green Kanha Run</h5>
          </div>

          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    Over half a million user interaction
                  </li>
                  <li class="list-group-item">
                    Over 355,000 Unique Page Views
                  </li>
                  <li class="list-group-item">Unmatched digital footprint</li>
                  <li class="list-group-item">
                    Digital Diversity - Engaging audience in 250+ cities
                    worldwide
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    Global web surge - 71000 visitors from over 30 countries
                  </li>
                  <li class="list-group-item">Avg. Session lasting 3 mins.</li>
                  <li class="list-group-item">Stellar user stays</li>
                  <li class="list-group-item">Top Engagement in Key States</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
