import React from "react";

import event1 from "../../assets/images/nov2023/celebrities/ravi_shastri.png";

import event2 from "../../assets/images/feb2023/event-stories/eventpic2.png";

import event3 from "../../assets/images/nov2023/celebrities/pullela_gopichand.png";

import event4 from "../../assets/images/feb2023/event-stories/eventpic4.png";

export default function CelebritiesTalking() {
  return (
    <div className="event-story-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="title-heading">
              Celebrities talking on Granules Green Kanha Run
            </h3>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="card mb-3">
              <div className="row g-0">
                <div className="col-md-5">
                  <img
                    className="img-fluid"
                    src={event1}
                    width="100%"
                    alt="event1"
                  />
                </div>
                <div className="col-md-7">
                  <div className="card-body">
                    <h5 className="card-title">Voiceover of Ravi Shastri</h5>
                    <p class="card-text">
                      <small class="text-muted">Former Indian Cricketer</small>
                    </p>
                    <p className="card-text">Followers - 1.4 million</p>
                    <p className="card-text">Video Plays - 32384</p>
                    <p className="card-text">Likes - 1089</p>
                    <p className="card-text">Shares - 220</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card mb-3">
              <div className="row g-0">
                <div className="col-md-5">
                  <img
                    className="img-fluid"
                    src={event1}
                    width="100%"
                    alt="event1"
                  />
                </div>
                <div className="col-md-7">
                  <div className="card-body">
                    <h5 className="card-title">VVS Laxman's Support</h5>
                    <p class="card-text">
                      <small class="text-muted">Former Indian Cricketer</small>
                    </p>
                    <p className="card-text">Followers - 1.4 Million</p>
                    <p className="card-text">Video Plays - 5056</p>
                    <p className="card-text">Likes - 139</p>
                    <p className="card-text">Shares -</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card mb-3">
              <div className="row g-0">
                <div className="col-md-5">
                  <img
                    className="img-fluid"
                    src={event3}
                    width="100%"
                    alt="event1"
                  />
                </div>
                <div className="col-md-7">
                  <div className="card-body">
                    <h5 className="card-title">Pullela Gopichand's Support</h5>
                    <p class="card-text">
                      <small class="text-muted">
                        Former Indian Badminton Player and Padma Bhushan{" "}
                      </small>
                    </p>
                    <p className="card-text">Followers - 1.4 million</p>
                    <p className="card-text">Video Plays - 32384</p>
                    <p className="card-text">Likes - 1089</p>
                    <p className="card-text">Shares - 220</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card mb-3">
              <div className="row g-0">
                <div className="col-md-5">
                  <img
                    className="img-fluid"
                    src={event1}
                    width="100%"
                    alt="event1"
                  />
                </div>
                <div className="col-md-7">
                  <div className="card-body">
                    <h5 className="card-title">R Sridhar's Engagement</h5>
                    <p class="card-text">
                      <small class="text-muted">
                        Current Bowling Coach of Kolkata Knight Riders
                      </small>
                    </p>
                    <p className="card-text">Followers - 307,000</p>
                    <p className="card-text">Video Plays - 12913</p>
                    <p className="card-text">Likes - 552</p>
                    <p className="card-text">Shares - 50</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card mb-3">
              <div className="row g-0">
                <div className="col-md-5">
                  <img
                    className="img-fluid"
                    src={event1}
                    width="100%"
                    alt="event1"
                  />
                </div>
                <div className="col-md-7">
                  <div className="card-body">
                    <h5 className="card-title">Bharati Arun's Impact</h5>
                    <p class="card-text">
                      <small class="text-muted">
                      Current Bowling Coach of Kolkata Knight Riders
                      </small>
                    </p>
                    <p className="card-text">Followers - 34.6k</p>
                    <p className="card-text">Video Plays - </p>
                    <p className="card-text">Likes -  667</p>
                    <p className="card-text">Shares - </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
